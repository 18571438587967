<template>
  <div>
    <LeftSidebar
      v-if="isLeftSideBarVisible"
      is-open
      @on-backdrop-click="isLeftSideBarToggled = false"
    />
    <RightSidebar
      :hub="hub"
      :is-open="isRightSideBarToggled"
      @on-backdrop-click="isRightSideBarToggled = false"
    />
    <TopNavigation
      :hub="hub"
      :is-left-side-bar-available="isLeftSideBarAvailable"
      :is-left-side-bar-visible="isLeftSideBarVisible"
      :is-left-side-bar-toggled="isLeftSideBarToggled"
      :style="{
        top: topOffset,
      }"
      @toggle-right-sidebar="handleToggleRightSidebar"
      @toggle-left-sidebar="handleToggleLeftSidebar"
    >
      <nav-item v-if="isPrintEnabled" :to="'/print'" :icon="PrinterIcon">
        {{ $t('navigation.print') }}
      </nav-item>
    </TopNavigation>
    <MaintenanceBanner
      v-if="showMaintenanceBanner"
      class="fixed"
      @close="handleCloseBanner"
    />
  </div>

  <div
    class="layout-container pt-16"
    :class="[
      authUser.isLoggedIn ? 'md:pl-70 max-w-[100rem]' : 'max-w-[70rem]',
      { 'pt-60 md:pt-40': showMaintenanceBanner },
      { 'md:pt-16': !authUser.isLoggedIn },
      { 'pb-25 md:pb-0': isLaunchHubContentNavigationEnabled },
    ]"
  >
    <main class="layout-content">
      <OnboardingBanner
        v-if="
          !orgSlug && authUser.isLoggedIn && organization && organization.isCoOp
        "
        class="mb-5"
      />
      <slot />
    </main>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { Footer } from '~/components/footer';
import { computed, ref, useIsMobile, useRuntimeConfig } from '#imports';
import { useAuthUserStore } from '~/store/auth-user';
import { useRoute } from 'nuxt/app';
import { TopNavigation } from '~/layouts/_components/top-navigation';
import { LeftSidebar } from '~/layouts/_components/left-sidebar';
import { RightSidebar } from '~/layouts/_components/right-sidebar';
import { MaintenanceBanner } from '~/layouts/_components/maintenance-banner';
import { MAINTENANCE_BANNER_HEIGHT } from '~/layouts/_components/maintenance-banner/maintenance-banner.constants';
import { apiGetSingleHub } from '~/api/hubs';
import { useAsyncData } from '#app';
import { OnboardingBanner } from '~/layouts/_components/onboarding-banner';
import { storeToRefs } from 'pinia';
import { useOrganizationStore } from '~/store/organization';
import { AccountType } from '~/api/accounts';
import { useAccountStore } from '~/store/account';
import PrinterIcon from '~/assets/icons/printer.svg?component';

const authUser = useAuthUserStore();
const config = useRuntimeConfig();
const isMobile = useIsMobile();
const route = useRoute();
const hubSlug = computed<string>(() => route.params.hubslug as string);
const orgSlug = computed<string>(() => route.params.organizationslug as string);
const organizationStore = useOrganizationStore();
const { organization } = storeToRefs(organizationStore);
const { currentAccount } = storeToRefs(useAccountStore());

const { data: hub } = useAsyncData(
  `hub-${hubSlug.value}`,
  () => {
    if (!hubSlug.value) {
      return Promise.resolve(null);
    }
    return apiGetSingleHub(hubSlug.value);
  },
  { watch: [hubSlug] },
);

const maintenanceBannerCookie = useCookie('maintenance-banner', {
  default: () => true,
});

const isLeftSideBarToggled = ref<boolean>(false);
const isRightSideBarToggled = ref<boolean>(false);
const isLeftSideBarAvailable = computed<boolean>(
  () =>
    !organization.value?.isPublic ||
    (Boolean(organization.value?.isPublic) && authUser.isLoggedIn),
);

const isLeftSideBarVisible = computed<boolean>(
  () =>
    isLeftSideBarAvailable.value &&
    (isLeftSideBarToggled.value || !isMobile.value),
);

const isContentDetails = computed(
  () => route.path === `/${route.params.hubslug}/${route.params.contentslug}`,
);

const isLaunchHubContentNavigationEnabled = computed(
  () =>
    currentAccount.value?.type === AccountType.STANDARD &&
    hub.value?.isLaunchEnabled &&
    isContentDetails.value,
);

const showMaintenanceBanner = computed(
  () =>
    config.public.isMaintenanceBannerEnabled && maintenanceBannerCookie.value,
);

const handleToggleRightSidebar = () => {
  isRightSideBarToggled.value = !isRightSideBarToggled.value;

  if (isLeftSideBarToggled.value) {
    isLeftSideBarToggled.value = false;
  }
};

const handleToggleLeftSidebar = () => {
  isLeftSideBarToggled.value = !isLeftSideBarToggled.value;

  if (isRightSideBarToggled.value) {
    isRightSideBarToggled.value = false;
  }
};

const handleCloseBanner = () => {
  sessionStorage.setItem('maintenance-banner', String(false));
  maintenanceBannerCookie.value = false;
};

const topOffset = computed(() =>
  showMaintenanceBanner.value && !isMobile.value
    ? MAINTENANCE_BANNER_HEIGHT
    : 0,
);

const isPrintEnabled = computed(() => {
  return organization.value?.isPrintEnabled ?? false;
});
</script>

<style scoped>
.layout-container {
  @apply w-full relative flex flex-col min-h-screen mx-auto;
}

.layout-content {
  @apply flex-1 p-5;
}
</style>
